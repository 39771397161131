import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProjectByIdQuery } from "../redux/api-slice";
import { useDispatch } from "react-redux";
import {
  setCredits,
  setCurrentDocument,
  setCurrentProject,
  setExpiryDate,
  setHasOpened,
  setPastMessages,
  setMissingDemoSessionId,
  setShouldStartIntro,
} from "../redux/application-slice";
import { toast } from "react-toastify";

// In Open Chat, this only validates the project ID, and assumes that the documentID will always be fetched via API.
export default function useValidateURL() {
  const { openChatSessionId, documentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: openChatProject,
    isLoading: openChatProjectIsLoading,
    error: openChatProjectError,
  } = useGetProjectByIdQuery(openChatSessionId);

  // First effect - handle project loading and errors
  useEffect(() => {
    if (!openChatProjectIsLoading && openChatProjectError) {
      navigate("/");
    }
  }, [openChatProjectIsLoading, openChatProjectError, navigate]);

  // Second effect - handle project data and document setting
  useEffect(() => {
    if (!openChatProjectIsLoading && openChatProject) {
      dispatch(setCurrentProject(openChatProject));
      dispatch(setCredits(openChatProject.credits));
      dispatch(setPastMessages(openChatProject.messages));
      dispatch(setExpiryDate(openChatProject.expiry_date));
      dispatch(setHasOpened(openChatProject.has_opened));

      // Handle document state after project is set
      if (openChatProject?.project?.documents?.length > 0) {
        const documents = openChatProject.project.documents;

        if (documentId) {
          const currentDoc = documents.find((doc) => doc.uuid === documentId);
          if (currentDoc) {
            // Small delay to ensure project state is set first. Avoid race condition.
            setTimeout(() => {
              dispatch(setCurrentDocument(currentDoc));
            }, 100);
          } else {
            navigate(`/${openChatSessionId}`);
          }
        } else {
          const firstDocument = documents[0];
          setTimeout(() => {
            dispatch(setCurrentDocument(firstDocument));
            navigate(`/${openChatSessionId}/chat/${firstDocument.uuid}`);
          }, 0);
        }
      }

      dispatch(setShouldStartIntro(true));
    }
  }, [
    openChatProject,
    openChatProjectIsLoading,
    documentId,
    navigate,
    dispatch,
    openChatSessionId,
  ]);

  // Third effect - handle missing session ID
  useEffect(() => {
    if (
      !openChatSessionId ||
      (openChatSessionId &&
        openChatProject === null &&
        !openChatProjectIsLoading)
    ) {
      dispatch(setMissingDemoSessionId(true));
      toast.warning(
        "No Demo Session Found. Please check your link was copied correctly, and if this persists, please reach out to support@useprovision.com",
        {
          autoClose: false,
          toastId: "validate-url-warning",
        }
      );
      return;
    }
    dispatch(setMissingDemoSessionId(false));
  }, [openChatSessionId, openChatProject, openChatProjectIsLoading, dispatch]);
}
