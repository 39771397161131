import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ModalController from "../components/modals/modal-controller";
import NavHeader from "../components/layout/nav-header";

import useValidateURL from "../hooks/use-validate-url";
import { Alert } from "@mantine/core";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { selectCurrentProject } from "../redux/application-slice";

export default function Root() {
  useValidateURL();
  const currentProject = useSelector(selectCurrentProject);

  return currentProject ? (
    <>
      <div className={"flex h-screen flex-col"}>
        <NavHeader />
        <Outlet />
      </div>

      <ToastContainer />
      <ModalController />
    </>
  ) : null;
}
