import Root from "./root";
import React from "react";
import * as Sentry from "@sentry/react";
import { RouteObject } from "react-router-dom";

import Home from "../components/home";
import AiChat from "../components/sidebar/ai-chat/ai-chat";

import NotFoundPage from "../components/404page/not-found-page";

const routesConfig: RouteObject[] = [
  {
    path: "/",
    element: <Root />,
    errorElement: <Sentry.ErrorBoundary showDialog />,
    children: [
      {
        path: "",
        element: <Home />,
        children: [
          {
            path: ":openChatSessionId/chat/:documentId",
            element: <AiChat />,
          },
          {
            path: ":openChatSessionId/chat",
            element: <AiChat />,
          },
          {
            path: ":openChatSessionId",
            element: <AiChat />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
      {
        path: "/404",
        element: <NotFoundPage />,
      },
    ],
  },
];

export default routesConfig;
