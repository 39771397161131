import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  DocumentResponse,
  ProjectDocument,
  ProjectDocumentMetadata,
  ProjectDocumentUpload,
} from "../shared/interfaces/project/document/document.interface";
import { Project } from "../shared/interfaces/project/project.interface";
import { InTextLink } from "../shared/interfaces/project/document/in-text-link/in-text-link.interface";
import { InTextSource } from "../shared/interfaces/project/document/in-text-source/in-text-source.interface";

import { Highlight } from "../shared/interfaces/project/document/highlight/highlight.interface";
import { DocumentBookmark } from "../shared/interfaces/project/document/bookmark/document-bookmark.interface";
import { ChecklistQuestion } from "../shared/interfaces/project/checklist-question/checklist-question.interface";
import { Risk } from "../shared/interfaces/project/risk/risk-inteface";
import {
  DeleteDocumentChangeDestinationParams,
  DocumentChange,
  DocumentChangeComment,
  DocumentChangeEndpointQueryParams,
  UpdateDocumentChange,
} from "../shared/interfaces/project/document/changes/document-change.interface";
import {
  ProjectPermission,
  ProjectPermissionUser,
} from "../shared/interfaces/project/permissions/project-permissions.interface";
import { ProvisionUser } from "../shared/interfaces/user/user.inteface";
import { ChatFeedback } from "../shared/interfaces/chat/chat-feedback.interface";
import { ProvisionNotification } from "../shared/interfaces/notification/notification.interface";
import {
  DocumentSegment,
  DocumentSegmentPagination,
} from "../shared/interfaces/project/document/segments/document-segment.interface";
import _ from "lodash";
import { DocumentBlacklineSegment } from "../shared/interfaces/project/document/blackline/document-blackline.interface";

import { Task, TaskStats } from "../shared/interfaces/task/task.interface";

import { Definition } from "../shared/interfaces/definition/definition.interface";
import {
  Folder,
  FolderCreate,
  FolderDelete,
  FolderMove,
  FolderRename,
} from "../shared/interfaces/project/folder/folder.interface";
import { buildQueryParams } from "../utils/build-query-params";
import { ChatMessage } from "../shared/interfaces/chat/chat-history.interface";

interface InviteToProjectParams {
  emails: {
    email: string;
  }[];
  projectID: number;
}

interface RiskSet {
  [riskID: string]: Risk;
}

interface UpdateDocumentByNameParams {
  document: ProjectDocument;
  newTitle: string;
  searchQuery?: string;
}

export interface RunQuestions {
  questionIds: number[];
  checklistView: string;
}

interface GetDocumentsQueryParameters {
  projectId?: number;
  latest_version_only?: boolean;
  folderId?: string | null;
  search_query?: string;
}

interface GetInTextLinks {
  documentUuid?: string;
  pageStart?: number;
  pageEnd?: number;
}

export interface ChangeProjectOrder {
  [id: number]: number;
}

export interface GetDocumentSegments {
  query?: string;
  projectId?: string;
  labels?: number[];
  documentIds?: string[];
  page: number;
  assigned_user?: number;
}

interface RiskExport {
  projectId: string;
  type: string;
  riskList?: string;
  riskSort?: string;
  documentUuids?: string[];
  searchQuery?: string;
}

export interface DocumentSortParams {
  folderId?: string;
  projectId: number;
  documentIndexes: {
    [documentId: number]: number;
  };
}

interface OpenChatProjectResponse {
  project: {
    id: number;
    uuid: string;
    title: string;
    documents: ProjectDocument[];
  };
  credits: number;
  expiry_date: string;
  has_opened: boolean;
  messages: ChatMessage[];
  agreed_to_terms: boolean; // RM THIS FROM THE BACKEND
}

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_APP_PROVISION_API_BASE_URL}`,
  }),
  tagTypes: [
    "Interaction",
    "Revision",
    "RevisionHistory",
    "RevisionStats",
    "RevisionComment",
    "Project",
    "ProjectPermission",
    "ChecklistQuestion",
    "Toc",
    "Document",
    "DocumentSegment",
    "Highlight",
    "Definitions",
    "Link",
    "Source",
    "Bookmark",
    "Risk",
    "Chat",
    "ProjectRisk",
    "RiskLists",
    "Risks",
    "Change",
    "CustomLabel",
    "ChecklistTemplate",
    "ChecklistTemplateQuestion",
    "ChecklistView",
    "DocumentChangeTemplate",
    "Notification",
    "Blackline",
    "Task",
    "CustomQARisk",
    "RiskCategory",
    "RiskPipelineDefinition",
    "EvaluationProjectRisk",
    "Organisation",
    "OrganisationMember",
    "UserProfile",
    "AddendaSegments",
  ],
  endpoints: (builder) => ({
    // Users
    getUserProfile: builder.query<ProvisionUser, void>({
      query: () => "/user/",
    }),
    getUserPermissions: builder.query<string[], void>({
      query: () => "/user/permissions/",
    }),
    updateUserProfile: builder.mutation({
      query: (userProfile) => ({
        url: "/user/",
        method: "PATCH",
        body: userProfile,
      }),
      async onQueryStarted(userProfile, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getUserProfile",
            undefined,
            (draft) => {
              // eslint-disable-next-line
              draft = { ...userProfile };
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ["UserProfile"],
    }),
    getNotifications: builder.query<ProvisionNotification[], boolean>({
      query: (isAll = false) => `/notifications/${isAll ? "?all=true" : ""}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Notification" as const,
                id,
              })),
              { type: "Notification" as const, id: "LIST" },
            ]
          : [{ type: "Notification" as const, id: "LIST" }],
    }),
    markNotificationsAsRead: builder.mutation<string[], string[]>({
      query: (notificationIDs) => ({
        url: "/notifications/read/",
        method: "POST",
        body: { notification_ids: notificationIDs },
      }),
      async onQueryStarted(notifications, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getNotifications", false, (draft) => {
            for (const d of draft) {
              const notification: ProvisionNotification = d;
              if (notifications.includes(notification.id)) {
                notification.is_read = true;
              }
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    // RISKS
    getRisksByProject: builder.query<Risk[], string>({
      query: (uuid) => `/risks/?project=${uuid}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Risk" as const, id })),
              { type: "Risk" as const, id: "LIST" },
            ]
          : [{ type: "Risk" as const, id: "LIST" }],
    }),
    getRisksByDocuments: builder.query<Risk[], string[]>({
      query: (documentIDs) => {
        const queryParams = buildQueryParams({
          documents: documentIDs,
        });
        return `/risks/?${queryParams}`;
      },
      transformResponse: (response: Risk[]) => {
        const riskSet: RiskSet = {};
        for (const risk of response) {
          if (!riskSet[risk.id]) {
            riskSet[risk.id] = risk;
          }
          if (
            riskSet[risk.id] &&
            !riskSet[risk.id].questions.length &&
            risk.questions.length
          ) {
            riskSet[risk.id].questions = risk.questions;
          }
          if (riskSet[risk.id]?.questions.length && risk.labels.length) {
            riskSet[risk.id].labels = risk.labels;
          }
        }
        return Object.keys(riskSet).map((key) => riskSet[key]) as Risk[];
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Risk" as const, id })),
              { type: "Risk" as const, id: "LIST" },
            ]
          : [{ type: "Risk" as const, id: "LIST" }],
    }),
    // CHANGES
    getDocumentChangesByDocument: builder.query<DocumentChange[], string>({
      query: (uuid) => `/documentchanges/?document=${uuid}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Change" as const, id })),
              { type: "Change" as const, id: "LIST" },
            ]
          : [{ type: "Change" as const, id: "LIST" }],
    }),
    getDocumentChangesByProject: builder.query<DocumentChange[], string>({
      query: (uuid) => `/documentchanges/?project=${uuid}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Change" as const, id })),
              { type: "Change" as const, id: "LIST" },
            ]
          : [{ type: "Change" as const, id: "LIST" }],
    }),
    getDocumentChanges: builder.query<
      DocumentChange[],
      DocumentChangeEndpointQueryParams
    >({
      query: ({ projectId, documentId, query }) =>
        `/changes/?${documentId ? `document=${documentId}` : ""}${
          projectId
            ? `${documentId ? "&project=" : "project="}${projectId}`
            : ""
        }${query ? `&query=${query}` : ""}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Change" as const,
                id,
              })),
              { type: "Change" as const, id: "LIST" },
            ]
          : [{ type: "Change" as const, id: "LIST" }],
    }),
    updateDocumentChanges: builder.mutation<
      DocumentChange,
      UpdateDocumentChange
    >({
      query: (documentChangeUpdate) => ({
        url: `/changes/${documentChangeUpdate.documentChange.id}/`,
        method: "PATCH",
        body: documentChangeUpdate.documentChange,
      }),
      invalidatesTags: (result, error, documentChangeUpdate) => [
        { type: "Change" as const, id: documentChangeUpdate.documentChange.id },
        { type: "Change" as const, id: "LIST" },
        { type: "AddendaSegments" as const, id: "LIST" },
      ],
      async onQueryStarted(documentChangeUpdate, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentChanges",
            documentChangeUpdate.queryParams,
            (draft) => {
              const index = draft.findIndex(
                (documentChangeItr) =>
                  documentChangeItr.id ===
                  documentChangeUpdate.documentChange.id
              );
              if (index === -1) {
                return;
              }
              draft[index] = {
                ...documentChangeUpdate.documentChange,
              };
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteDocumentChangeDestination: builder.mutation<
      DocumentChange,
      DeleteDocumentChangeDestinationParams
    >({
      query: ({ documentChangeId, destinationId }) => ({
        url: `/changes/${documentChangeId}/delete_destination/`,
        method: "POST",
        body: { destination_id: destinationId },
      }),
      invalidatesTags: (result, error, { documentChangeId }) => [
        { type: "Change" as const, id: documentChangeId },
        { type: "Change" as const, id: "LIST" },
        { type: "AddendaSegments" as const, id: "LIST" },
      ],
      async onQueryStarted(
        { projectUUID, documentChangeId, destinationId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentChangesByProject",
            projectUUID,
            (draft) => {
              const documentChange = draft.find(
                (documentChange) => documentChange.id === documentChangeId
              );
              if (!documentChange) {
                return;
              }
              documentChange.destinations = documentChange.destinations?.filter(
                (destination) => destination.id !== destinationId
              );
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    // PROJECTS
    createProject: builder.mutation<Project, Project>({
      query: (project) => ({
        url: "/projects/",
        method: "POST",
        body: project,
      }),
      invalidatesTags: [{ type: "Project", id: "LIST" }],
      onQueryStarted: async (project, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getProjects", undefined, (draft) => {
            draft.unshift(project);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    createDocument: builder.mutation<
      ProjectDocumentUpload,
      Partial<ProjectDocument>
    >({
      query: (document) => ({
        url: "/documents/",
        method: "POST",
        body: { ...document, folder: document.folder?.id },
      }),
      invalidatesTags: [{ type: "Document", id: "LIST" }],
    }),
    getProjects: builder.query<Project[], string | undefined>({
      query: (chatSessionId) => {
        if (chatSessionId) {
          return `/openchatprojects/${chatSessionId}`;
        }
        return;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Project" as const, id })),
              { type: "Project" as const, id: "LIST" },
            ]
          : [{ type: "Project" as const, id: "LIST" }],
    }),
    updateProjectOrder: builder.mutation<string, ChangeProjectOrder>({
      query: (changeProjectOrder) => ({
        url: `/projects/change_order/`,
        method: "POST",
        body: {
          project_index_order: changeProjectOrder,
        },
      }),
      async onQueryStarted(changeProjectOrder, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getProjects", undefined, (draft) => {
            draft.sort((a, b) => {
              const aIndex = changeProjectOrder[a.id ?? ""];
              const bIndex = changeProjectOrder[b.id ?? ""];
              if (aIndex === undefined || bIndex === undefined) {
                return 0;
              }
              return bIndex - aIndex;
            });
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getProjectById: builder.query<OpenChatProjectResponse, string>({
      query: (chatSessionId) => `/openchat/${chatSessionId}/`,
    }),
    getProjectPermissionsById: builder.query<ProjectPermission, number>({
      query: (projectId) => `/projects/${projectId}/permissions/`,
      providesTags: () => [{ type: "ProjectPermission" as const, id: "LIST" }],
    }),
    getOrganizationUsers: builder.query<ProvisionUser[], undefined>({
      query: () => "user/organization/",
    }),
    inviteUserToProject: builder.mutation<
      InviteToProjectParams,
      InviteToProjectParams
    >({
      query: ({ projectID, emails }) => ({
        url: `/projects/${projectID}/invite/`,
        method: "POST",
        body: emails,
      }),
      invalidatesTags: [{ type: "ProjectPermission", id: "LIST" }],
    }),
    inviteOrgToProject: builder.mutation<number, number>({
      query: (projectID) => ({
        url: "user/organization/",
        method: "POST",
        body: {
          project: projectID,
        },
      }),
      invalidatesTags: [{ type: "ProjectPermission", id: "LIST" }],
    }),
    updateProject: builder.mutation({
      query: (project) => ({
        url: `/projects/${project.id}/`,
        method: "PATCH",
        body: project,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Project", id }],
      async onQueryStarted(project, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData("getProjects", undefined, (draft) => {
            const index = draft.findIndex(
              (projectItr: Project) => projectItr.id === project.id
            );
            if (index !== -1) {
              draft[index] = { ...draft[index], ...project };
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteProject: builder.mutation({
      query: (projectId) => ({
        url: `/projects/${projectId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Project", id: "LIST" }],
      async onQueryStarted(projectId, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          apiSlice.util.updateQueryData("getProjects", undefined, (draft) => {
            const index = draft.findIndex(
              (project: Project) => project.id === projectId
            );
            if (index !== -1) {
              draft.splice(index, 1);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
    }),
    // TYPESENSE EXPORT
    createTypesenseExport: builder.mutation({
      query: ({
        projectUuid,
        query = "",
        documentUuids = [],
        labels = [],
      }) => ({
        url: "/typesense/export/",
        method: "POST",
        body: {
          project: projectUuid,
          query,
          document_uuids: documentUuids,
          labels,
        },
      }),
    }),
    // DOCUMENTS
    getDocumentsByProject: builder.query<
      DocumentResponse,
      GetDocumentsQueryParameters
    >({
      query: ({ projectId, latest_version_only, folderId, search_query }) =>
        `v2/documents/?project=${projectId}${
          latest_version_only ? "&latest_version_only=true" : ""
        }${folderId !== undefined ? `&folder=${folderId}` : ""}${
          search_query ? `&search_query=${search_query}` : ""
        }`,
      providesTags: (result) =>
        result
          ? [
              ...result.documents.map(({ id }) => ({
                type: "Document" as const,
                id,
              })),
              { type: "Document" as const, id: "LIST" },
            ]
          : [{ type: "Document" as const, id: "LIST" }],
    }),
    getDocumentsListByProject: builder.query<
      ProjectDocumentMetadata[],
      Omit<GetDocumentsQueryParameters, "folderId">
    >({
      query: ({ projectId, latest_version_only, search_query }) =>
        `/documents/?project=${projectId}${
          latest_version_only ? "&latest_version_only=true" : ""
        }${search_query ? `&search_query=${search_query}` : ""}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Document" as const,
                id,
              })),
              { type: "Document" as const, id: "LIST" },
            ]
          : [{ type: "Document" as const, id: "LIST" }],
    }),
    getDocumentStatusByProject: builder.query<
      ProjectDocument[],
      number | string
    >({
      query: (projectId) => `/projects/${projectId}/status/`,
    }),
    getDocumentByUuid: builder.query<ProjectDocument | null, string>({
      query: (documentUuid) => `/documents/${documentUuid}/`,
      providesTags: (result) =>
        result
          ? [{ type: "Document" as const, id: result.id }]
          : [{ type: "Document" as const, id: "LIST" }],
    }),
    updateDocumentByName: builder.mutation<
      ProjectDocument,
      UpdateDocumentByNameParams
    >({
      query: ({ document, newTitle }) => ({
        url: `/documents/${document.uuid}/`,
        method: "PATCH",
        body: {
          title: newTitle,
        },
      }),
      invalidatesTags: () => [{ type: "Document", id: "LIST" }],
      async onQueryStarted(
        { document, newTitle, searchQuery },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentsByProject",
            {
              projectId: document.project,
              folderId: !document?.folder?.id ? null : document.folder.id,
              search_query: searchQuery,
            },
            (draft) => {
              const docToUpdate: ProjectDocument | undefined =
                draft.documents.find(
                  (doc: ProjectDocument) => document.uuid === doc.uuid
                );
              if (docToUpdate) {
                docToUpdate.title = newTitle;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteDocument: builder.mutation<undefined, Partial<ProjectDocument>>({
      query: ({ uuid }) => ({
        url: `/documents/${uuid}/`,
        method: "DELETE",
      }),
      // optimistic update
      async onQueryStarted(
        { uuid, project, folder, search_query },
        { dispatch, queryFulfilled }
      ) {
        const deleteResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentsByProject",
            {
              projectId: project ?? -1,
              folderId: !folder?.id ? null : folder.id,
              search_query,
            },
            (draft) => {
              const index = draft.documents.findIndex(
                (document: ProjectDocument) => document.uuid === uuid
              );
              if (index !== -1) {
                draft.documents.splice(index, 1);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
      invalidatesTags: (result, error, document) => [
        { type: "Document", id: document?.id },
        { type: "Document", id: "LIST" },
      ],
    }),
    // INTERACTIONS
    getInteractionByDocumentUuid: builder.query({
      query: (documentUuid) => `/interactions/${documentUuid}/`,
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [{ type: "Interaction", id: "LIST" }]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "Interaction", id: "LIST" }],
    }),
    updateInteraction: builder.mutation({
      query: (interaction) => ({
        url: `/interactions/${interaction.id}/`,
        method: "PATCH",
        body: interaction.content,
      }),
      invalidatesTags: () => [{ type: "Interaction", id: "LIST" }],
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDefinitionsByDocumentUuid",
            0,
            (draft) => {
              Object.assign(draft, patch.content);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    // TOC
    getTocByDocumentUuid: builder.query({
      query: (documentUuid) => `/documents/${documentUuid}/toc/`,
      providesTags: ["Toc"],
    }),
    // Document processing status
    getDocumentProcessingStatus: builder.query({
      query: (documentUuid) => `/documents/${documentUuid}/status/`,
    }),
    // DEFINITIONS
    getDefinitionsByDocumentUuid: builder.query({
      query: (documentUuid) =>
        `/sources/definitions/?documents=${documentUuid}`,
      providesTags: ["Definitions"],
    }),
    getDefinitionsByQuery: builder.query<
      Definition[],
      { projectId?: number; documentIds?: string[] }
    >({
      query: ({ projectId, documentIds }) =>
        `/sources/definitions/?${projectId ? `project=${projectId}` : ""}${
          documentIds?.length
            ? documentIds
                .map((d, i) => `${i === 0 ? "" : "&"}documents=${d}`)
                .join("")
            : ""
        }`,
      providesTags: ["Definitions"],
    }),
    // LINKS
    getInTextLinks: builder.query<InTextLink[], GetInTextLinks>({
      query: ({ documentUuid, pageStart, pageEnd }) =>
        `/links/?document=${documentUuid}&page_start=${pageStart}${
          pageEnd ? `&page_end=${pageEnd}` : ""
        }`,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: "Link" as const, id })), "Link"]
          : ["Link"],
    }),
    getInTextSource: builder.query<InTextSource, number>({
      query: (sourceId) => `/sources/${sourceId}`,
      providesTags: (result) =>
        result
          ? [{ type: "Source" as const, id: result.id }, "Source"]
          : ["Source"],
    }),
    // SEGMENTS
    getDocumentSegments: builder.query<
      DocumentSegmentPagination,
      GetDocumentSegments
    >({
      query: ({
        projectId,
        query,
        labels,
        documentIds,
        page,
        assigned_user,
      }) => {
        return `/segments/${
          documentIds?.length
            ? documentIds
                .map((d, i) => `${i === 0 ? "?" : "&"}documents=${d}`)
                .join("")
            : `?project=${projectId}`
        }${query ? `&query=${query}` : ""}${
          assigned_user ? `&assigned_user=${assigned_user}` : ""
        }${
          labels ? labels.map((l) => `&labels=${l}`).join("") : ""
        }${`&page=${page}`}`;
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { projectId, query, labels, documentIds, assigned_user } =
          queryArgs;
        return `/segments/${
          documentIds?.length
            ? documentIds
                .map((d, i) => `${i === 0 ? "?" : "&"}documents=${d}`)
                .join("")
            : `?project=${projectId}`
        }${query ? `&query=${query}` : ""}${
          assigned_user ? `&assigned_user=${assigned_user}` : ""
        }${labels ? labels.map((l) => `&labels=${l}`).join("") : ""}`;
      },
      merge: (currentCache, newItems) => {
        currentCache.results.push(...newItems.results);
      },
      forceRefetch({ currentArg, previousArg }) {
        return !_.isEqual(currentArg, previousArg);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "DocumentSegment" as const,
                id,
              })),
              { type: "DocumentSegment" as const, id: "LIST" },
            ]
          : [{ type: "DocumentSegment" as const, id: "LIST" }],
    }),
    createTaskFromSegments: builder.mutation<Task, Task>({
      query: (task) => ({
        url: `/segments/${task.segment}/create_task/`,
        method: "POST",
        body: task,
      }),
      onQueryStarted: async (task, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentSegments",
            task.getDocumentSegments ?? { page: 1 },
            (draft) => {
              const documentSegment = draft.results?.find(
                (documentSegment) => documentSegment.id === task.segment
              );
              if (!documentSegment) {
                return;
              }
              documentSegment.tasks = [...(documentSegment.tasks ?? []), task];
            }
          )
        );
        try {
          const result = (await queryFulfilled).data;
          dispatch(
            apiSlice.util.updateQueryData(
              "getDocumentSegments",
              task.getDocumentSegments ?? { page: 1 },
              (draft) => {
                const documentSegment = draft.results?.find(
                  (documentSegment) => documentSegment.id === task.segment
                );
                if (!documentSegment) {
                  return;
                }
                if (
                  !documentSegment?.tasks?.[documentSegment.tasks.length - 1]
                ) {
                  return;
                }
                documentSegment.tasks[documentSegment.tasks.length - 1].id =
                  result.id;
              }
            )
          );
          dispatch(
            apiSlice.util.updateQueryData(
              "getTaskStats",
              task?.project
                ? {
                    projectId: task.project,
                  }
                : {
                    projectId: -1,
                  },
              (draft) => {
                const user = draft.find((taskStats) => {
                  return taskStats.assigned_users.id === task.user_created?.id;
                });
                if (!user && task?.user_created) {
                  const taskUser: ProjectPermissionUser = {
                    ...task.user_created,
                    email: task.user_created?.email ?? "",
                  };
                  draft.push({
                    assigned_users: taskUser,
                    count: 1,
                  });
                } else if (user) {
                  user.count += 1;
                }
              }
            )
          );
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "DocumentSegment", id: "LIST" }],
    }),
    // IMAGES
    getDocumentPageImage: builder.query({
      query: ({ documentId, page }) => `/images/${documentId}/${page}/`,
    }),
    // SECTION REFERENCES
    getSectionReferencesByDocumentUuid: builder.query({
      query: (documentUuid) => `/documents/${documentUuid}/sectionreferences/`,
    }),
    createDocumentChangeComment: builder.mutation<
      DocumentChangeComment,
      DocumentChangeComment
    >({
      query: (documentChangeComment) => ({
        url: "/documentchangecomments/",
        method: "POST",
        body: documentChangeComment,
      }),
      async onQueryStarted(
        documentChangeComment,
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentChanges",
            {
              documentId: documentChangeComment.document,
              projectId: documentChangeComment.project,
              query: documentChangeComment.query,
            },
            (draft) => {
              const documentChange = documentChangeComment.parent;
              if (!documentChange) {
                return;
              }
              const index = draft.findIndex(
                (documentChangeItr) =>
                  documentChangeItr.id === documentChange.id
              );
              if (index !== -1) {
                draft[index].comments?.push({
                  comment: documentChangeComment.comment,
                  mentions: documentChangeComment.mentions,
                  date_created: new Date().toISOString(),
                  user: documentChangeComment.user,
                });
              }
            }
          )
        );
        try {
          const results = (await queryFulfilled).data;
          dispatch(
            apiSlice.util.updateQueryData(
              "getDocumentChanges",
              {
                documentId: documentChangeComment.document,
                projectId: documentChangeComment.project,
                query: documentChangeComment.query,
              },
              (draft) => {
                const documentChange = draft?.find(
                  (d) => d?.id === results?.document_change_id
                );
                if (!documentChange) {
                  return;
                }
                if (!documentChange.comments) {
                  documentChange.comments = [results];
                  return;
                }
                documentChange.comments = [
                  ...(documentChange?.comments?.filter((c) => c.id) ?? []),
                  results,
                ];
              }
            )
          );
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "Change", id: "LIST" }],
    }),
    updateDocumentChangeComment: builder.mutation<
      DocumentChangeComment,
      DocumentChangeComment
    >({
      query: (documentChangeComment) => ({
        url: `/documentchangecomments/${documentChangeComment.id}/`,
        method: "PATCH",
        body: documentChangeComment,
      }),
      async onQueryStarted(
        documentChangeComment,
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentChanges",
            {
              documentId: documentChangeComment.document,
              projectId: documentChangeComment.project,
              query: documentChangeComment.query,
            },
            (draft) => {
              const documentChange = documentChangeComment.parent;
              if (!documentChange) {
                return;
              }
              const index = draft.findIndex(
                (documentChangeItr) =>
                  documentChangeItr.id === documentChange.id
              );
              if (index === -1) {
                return;
              }
              const commentsIndex = draft[index]?.comments?.findIndex(
                (c) => c.id === documentChangeComment.id
              );
              const cleanIndex =
                commentsIndex === undefined ? -1 : commentsIndex;
              if (cleanIndex === -1) {
                return;
              }
              const comments = draft[index]?.comments;
              if (!comments) {
                return;
              }
              if (!comments[cleanIndex]) {
                return;
              }
              comments[cleanIndex] = documentChangeComment;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "Change", id: "LIST" }],
    }),
    deleteDocumentChangeComment: builder.mutation<
      DocumentChangeComment,
      DocumentChangeComment
    >({
      query: ({ id }) => ({
        url: `/documentchangecomments/${id}/`,
        method: "DELETE",
      }),
      // optimistic update
      async onQueryStarted(
        documentChangeComment,
        { dispatch, queryFulfilled }
      ) {
        const deleteResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentChanges",
            {
              documentId: documentChangeComment.document,
              projectId: documentChangeComment.project,
              query: documentChangeComment.query,
            },
            (draft) => {
              const index = draft.findIndex(
                (documentChange) =>
                  documentChange.id === documentChangeComment.document_change_id
              );
              const commentsIndex =
                documentChangeComment?.parent?.comments?.findIndex(
                  (rc) => rc?.id === documentChangeComment?.id
                );
              const cleanIndex =
                commentsIndex === undefined ? -1 : commentsIndex;
              if (cleanIndex === -1) {
                return;
              }
              if (
                cleanIndex !== -1 &&
                documentChangeComment?.parent?.comments
              ) {
                if ((draft[index]?.comments?.length ?? 0) > 1) {
                  draft[index]?.comments?.splice(cleanIndex, 1);
                } else {
                  draft[index].comments = [];
                }
              }
              draft[index] = { ...draft[index] };
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
    }),

    createRiskExport: builder.mutation<{ export_url }, RiskExport>({
      query: ({
        projectId,
        type,
        riskList,
        riskSort,
        documentUuids,
        searchQuery,
      }) => ({
        url: `/projectrisks/export/?project=${projectId}&file_type=${type}${
          riskList ? `&risk_list=${riskList}` : ""
        }${riskSort ? `&sort=${riskSort}` : ""}${
          documentUuids
            ? documentUuids.map((d) => `&document_uuids=${d}`).join("")
            : ""
        }${searchQuery ? `&search_query=${searchQuery}` : ""}`,
        method: "POST",
      }),
    }),
    createDocumentChangesExport: builder.mutation({
      query: ({ projectId, documentId, file_type }) => ({
        url: `/changes/export/?${documentId ? `document=${documentId}` : ""}${
          projectId
            ? `${documentId ? "&project=" : "project="}${projectId}`
            : ""
        }&file_type=${file_type}`,
        method: "POST",
      }),
    }),
    // HIGHLIGHTS
    createHighlight: builder.mutation<Highlight, Highlight>({
      query: (highlight) => ({
        url: "/highlights/",
        method: "POST",
        body: highlight,
      }),
      onQueryStarted: async (highlight, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getHighlights",
            highlight.document ?? -1,
            (draft) => {
              draft.push(highlight);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "Highlight", id: "LIST" }],
    }),
    getHighlights: builder.query<Highlight[], number>({
      query: (documentId) => `/highlights/?document=${documentId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Highlight" as const, id })),
              { type: "Highlight" as const, id: "LIST" },
            ]
          : [{ type: "Highlight" as const, id: "LIST" }],
    }),
    updateHighlight: builder.mutation<Highlight, Highlight>({
      query: (highlight) => ({
        url: `/highlights/${highlight.id}/`,
        method: "PATCH",
        body: highlight,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Highlight", id }],
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getHighlights",
            patch.document ?? -1,
            (draft) => {
              Object.assign(draft, patch);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteHighlight: builder.mutation({
      query: ({ id }) => ({
        url: `/highlights/${id}/`,
        method: "DELETE",
      }),
      // optimistic update
      async onQueryStarted({ id, document }, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          apiSlice.util.updateQueryData("getHighlights", document, (draft) => {
            const index = draft.findIndex((highlight) => highlight.id === id);
            if (index !== -1) {
              draft.splice(index, 1);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
      invalidatesTags: (result, error, id) => [{ type: "Highlight", id }],
    }),
    // BOOKMARKS
    createBookmark: builder.mutation<DocumentBookmark, DocumentBookmark>({
      query: (bookmark) => ({
        url: "/bookmarks/",
        method: "POST",
        body: bookmark,
      }),
      onQueryStarted: async (bookmark, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getBookmarks",
            bookmark.document,
            (draft) => {
              draft.push(bookmark);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
        // return queryFulfilled;
      },
      invalidatesTags: [{ type: "Bookmark", id: "LIST" }],
    }),
    getBookmarks: builder.query<DocumentBookmark[], number>({
      query: (documentId) => `/bookmarks/?document=${documentId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Bookmark" as const, id })),
              { type: "Bookmark" as const, id: "LIST" },
            ]
          : [{ type: "Bookmark" as const, id: "LIST" }],
    }),
    updateBookmark: builder.mutation({
      query: (bookmark) => ({
        url: `/bookmarks/${bookmark.id}/`,
        method: "PATCH",
        body: bookmark,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Bookmark", id }],
      async onQueryStarted(bookmark, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getBookmarks",
            bookmark.document,
            (draft) => {
              const index = draft.findIndex(
                (bookmarkItr) => bookmarkItr.id === bookmark.id
              );
              if (index !== -1) {
                draft[index] = { ...draft[index], ...bookmark };
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteBookmark: builder.mutation({
      query: ({ id }) => ({
        url: `/bookmarks/${id}/`,
        method: "DELETE",
      }),
      // optimistic update
      async onQueryStarted({ id, document }, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          apiSlice.util.updateQueryData("getBookmarks", document, (draft) => {
            const index = draft.findIndex((bookmark) => bookmark.id === id);
            if (index !== -1) {
              draft.splice(index, 1);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
      invalidatesTags: (result, error, id) => [{ type: "Bookmark", id }],
    }),
    //   QUERY LOGS
    updateQueryLog: builder.mutation({
      query: (querylog) => ({
        url: `/querylogs/${querylog.id}/`,
        method: "PATCH",
        body: querylog,
      }),
    }),
    createChatFeedback: builder.mutation<ChatFeedback, ChatFeedback>({
      query: (querylog) => ({
        url: "/openchatfeedback/",
        method: "POST",
        body: querylog,
      }),
    }),
    // CHECKLIST
    getChecklistQuestions: builder.query<ChecklistQuestion[], string>({
      query: (projectId) => `/questions/?project=${projectId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "ChecklistQuestion" as const,
                id,
              })),
              { type: "ChecklistQuestion" as const, id: "LIST" },
            ]
          : [{ type: "ChecklistQuestion" as const, id: "LIST" }],
    }),
    getChecklistQuestionsByTemplateView: builder.query<
      ChecklistQuestion[],
      string
    >({
      query: (templateViewID) => `/checklistquestions/?view=${templateViewID}`,
      providesTags: ["ChecklistQuestion"],
    }),
    updateChecklistQuestion: builder.mutation({
      query: (question) => ({
        url: `/questions/${question.id}/`,
        method: "PATCH",
        body: question.content,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getChecklistQuestionsByTemplateView",
            patch.content.checklist_view ?? "",
            (draft) => {
              const index = draft.findIndex(
                (checklistQuestionItr) => checklistQuestionItr.id === id
              );
              if (index !== -1) {
                draft[index] = { ...draft[index], ...patch.content };
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    createChecklistQuestion: builder.mutation<
      ChecklistQuestion,
      ChecklistQuestion
    >({
      query: (question) => ({
        url: "/questions/",
        method: "POST",
        body: question,
      }),
      async onQueryStarted(question, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getChecklistQuestionsByTemplateView",
            question.checklist_view ?? "",
            (draft) => {
              draft.push({ ...question, status: 1 });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "ChecklistQuestion", id: "LIST" }],
    }),
    createChecklistQuestionRunMany: builder.mutation({
      query: (questionIds) => ({
        url: "/questions/run/",
        method: "POST",
        body: { question_ids: questionIds },
      }),
    }),
    documentChangeRunMany: builder.mutation({
      query: (documentChangeIDs) => ({
        url: "/changes/run/",
        method: "POST",
        body: { document_change_ids: documentChangeIDs },
      }),
    }),
    createChecklistQuestionRunManyByView: builder.mutation<null, RunQuestions>({
      query: ({ questionIds }) => ({
        url: "/questions/run/",
        method: "POST",
        body: { question_ids: questionIds },
      }),
      async onQueryStarted({ checklistView }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getChecklistQuestionsByTemplateView",
            checklistView ?? "",
            (draft) => {
              draft = draft.slice().map((d) => {
                return {
                  ...d,
                  status: 1,
                };
              });
              return draft;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteChecklistQuestion: builder.mutation({
      query: ({ id }) => ({
        url: `/questions/${id}/`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { id, checklist_view },
        { dispatch, queryFulfilled }
      ) {
        const deleteResult = dispatch(
          apiSlice.util.updateQueryData(
            "getChecklistQuestionsByTemplateView",
            checklist_view,
            (draft) => {
              const index = draft.findIndex(
                (checklistQuestion: ChecklistQuestion) =>
                  checklistQuestion.id === id
              );
              if (index !== -1) {
                draft.splice(index, 1);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
      invalidatesTags: (result, error, id) => [
        { type: "ChecklistQuestion", id },
      ],
    }),
    getDocumentBlacklines: builder.query<
      DocumentBlacklineSegment[],
      { page: number; documentID: number }
    >({
      query: ({ documentID, page }) =>
        `/documentblacklinesegments/?document=${documentID}&page=${page}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Blackline" as const, id })),
              "Blackline",
            ]
          : ["Blackline"],
    }),
    exportDocumentDiff: builder.mutation({
      query: (documentId: number) => ({
        url: `/documents/export_diff/?document=${documentId}`,
        method: "POST",
      }),
    }),
    getTaskStats: builder.query<TaskStats[], { projectId: number }>({
      query: ({ projectId }) => `/taskstats/?project=${projectId}`,
    }),
    updateTaskStatus: builder.mutation<Task, Task>({
      query: (task) => ({
        url: `/tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      onQueryStarted: async (task, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentSegments",
            task.getDocumentSegments ?? { page: 1 },
            (draft) => {
              const documentSegment = draft.results?.find(
                (documentSegment) => documentSegment.id === task.segment
              );
              if (!documentSegment) {
                return;
              }
              const documentSegmentTask = documentSegment.tasks?.find(
                (documentSegmentTask) => documentSegmentTask.id === task.id
              );
              if (!documentSegmentTask) {
                return;
              }
              documentSegmentTask.status = task.status;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateTaskDescription: builder.mutation<Task, Task>({
      query: (task) => ({
        url: `/tasks/${task.id}/`,
        method: "PATCH",
        body: task,
      }),
      onQueryStarted: async (task, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentSegments",
            task.getDocumentSegments ?? { page: 1 },
            (draft) => {
              const documentSegment = draft.results?.find(
                (documentSegment) => documentSegment.id === task.segment
              );
              if (!documentSegment) {
                return;
              }
              const documentSegmentTask = documentSegment.tasks?.find(
                (documentSegmentTask) => documentSegmentTask.id === task.id
              );
              if (!documentSegmentTask) {
                return;
              }
              documentSegmentTask.description = task.description;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    unarchiveProject: builder.mutation<Project, Project>({
      query: (project: Project) => ({
        url: `/projects/${project.id}/unarchive/`,
        method: "POST",
      }),
      async onQueryStarted(project, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentsByProject",
            { projectId: project.id },
            (draft) => {
              for (const document of draft.documents) {
                if (document.job_status === "ARCHIVED") {
                  document.job_status = "UNARCHIVING";
                }
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteTask: builder.mutation<Task, Task>({
      query: ({ id }) => ({
        url: `/tasks/${id}/`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { id, getDocumentSegments, segment, project, user_created },
        { dispatch, queryFulfilled }
      ) {
        const deleteResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentSegments",
            getDocumentSegments ?? { page: 1 },
            (draft) => {
              const documentSegmentIndex = draft.results?.findIndex(
                (documentSegment) => documentSegment.id === segment
              );
              if (documentSegmentIndex === -1) {
                return;
              }
              const documentSegmentTaskIndex = draft?.results?.[
                documentSegmentIndex
              ]?.tasks?.findIndex(
                (documentSegmentTask) => documentSegmentTask.id === id
              );
              if (
                documentSegmentTaskIndex === -1 ||
                documentSegmentTaskIndex === undefined
              ) {
                return;
              }
              draft.results?.[documentSegmentIndex]?.tasks?.splice(
                documentSegmentTaskIndex,
                1
              );
            }
          )
        );
        dispatch(
          apiSlice.util.updateQueryData(
            "getTaskStats",
            project
              ? {
                  projectId: project,
                }
              : {
                  projectId: -1,
                },
            (draft) => {
              const userIndex = draft.findIndex((taskStats) => {
                return taskStats.assigned_users.id === user_created?.id;
              });
              if (userIndex !== -1) {
                if (draft[userIndex].count === 1) {
                  draft.splice(userIndex, 1);
                } else {
                  draft[userIndex].count -= 1;
                }
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
    }),
    getAddendaSegments: builder.query<DocumentSegment[], number>({
      query: (documentId) => `/addendasegments/?document=${documentId}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "AddendaSegments" as const,
                id,
              })),
              "AddendaSegments",
              { type: "AddendaSegments" as const, id: "LIST" },
            ]
          : [{ type: "AddendaSegments" as const, id: "LIST" }],
    }),
    updateDocumentSort: builder.mutation<undefined, DocumentSortParams>({
      query: ({ projectId, documentIndexes, folderId }) => ({
        url: `/projects/${projectId}/document_sort_order/`,
        method: "POST",
        body: { document_index_order: documentIndexes, folder: folderId },
      }),
      async onQueryStarted(
        { projectId, documentIndexes, folderId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentsByProject",
            {
              projectId: projectId,
              folderId: !folderId ? null : folderId,
            },
            (draft) => {
              draft.documents.sort((a, b) => {
                return documentIndexes[a.id] - documentIndexes[b.id];
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "Document", id: "LIST" }, { type: "Document" }],
    }),
    createFolder: builder.mutation<Folder, FolderCreate>({
      query: (folder) => ({
        url: "/folders/",
        method: "POST",
        body: folder,
      }),
      async onQueryStarted(
        { project, folder, name },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentsByProject",
            { projectId: project, folderId: !folder ? null : folder },
            (draft) => {
              draft.folders.push({
                project,
                name,
                id: "",
                documents: [],
                folders: [],
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "Document", id: "LIST" }],
    }),
    renameFolder: builder.mutation<Folder, FolderRename>({
      query: ({ id, name }) => ({
        url: `/folders/${id}/`,
        method: "PATCH",
        body: {
          name,
        },
      }),
      async onQueryStarted(
        { projectId, folderId, id, name, searchQuery },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentsByProject",
            {
              projectId,
              folderId: !folderId ? null : folderId,
              search_query: searchQuery,
            },
            (draft) => {
              const folderIndex = draft.folders.findIndex((f) => f.id === id);
              if (folderIndex !== -1) {
                draft.folders[folderIndex].name = name;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "Document", id: "LIST" }],
    }),
    deleteFolder: builder.mutation<Folder, FolderDelete>({
      query: ({ id }) => ({
        url: `/folders/${id}/`,
        method: "DELETE",
      }),
      async onQueryStarted(
        { id, projectId, folderId, searchQuery },
        { dispatch, queryFulfilled }
      ) {
        const deleteResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentsByProject",
            {
              projectId: projectId,
              folderId: !folderId ? null : folderId,
              search_query: searchQuery,
            },
            (draft) => {
              const folder = draft.folders.find((f) => f.id === id);
              if (folder) {
                draft.folders.splice(draft.folders.indexOf(folder), 1);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
      invalidatesTags: [{ type: "Document", id: "LIST" }],
    }),
    moveItemsIntoFolder: builder.mutation<Folder, FolderMove>({
      query: ({ destination_folder_id, document_ids, folder_ids }) => ({
        url: `/folders/move/`,
        method: "POST",
        body: {
          document_ids,
          folder_ids,
          destination_folder_id,
        },
      }),
      async onQueryStarted(
        { document_ids, folder_ids, projectId, folderId, searchQuery },
        { dispatch, queryFulfilled }
      ) {
        const deleteResult = dispatch(
          apiSlice.util.updateQueryData(
            "getDocumentsByProject",
            {
              projectId: projectId,
              folderId: !folderId ? null : folderId,
              search_query: searchQuery,
            },
            (draft) => {
              draft.folders = draft.folders.filter(
                (f) => !folder_ids.includes(f.id)
              );
              draft.documents = draft.documents.filter(
                (d) => !document_ids.includes(d.id)
              );
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
      invalidatesTags: [{ type: "Document", id: "LIST" }],
    }),
  }),
});

export const {
  // Users
  useGetUserProfileQuery,
  useGetUserPermissionsQuery,
  useUpdateUserProfileMutation,
  //   Interactions
  useGetInteractionByDocumentUuidQuery,
  useUpdateInteractionMutation,
  //   Document processing status
  useGetDocumentProcessingStatusQuery,
  //   TOC
  useGetTocByDocumentUuidQuery,
  //   Definitions
  useGetDefinitionsByDocumentUuidQuery,
  //   Projects
  useGetProjectsQuery,
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useInviteUserToProjectMutation,
  useUpdateProjectOrderMutation,
  //   Documents
  useGetDocumentsByProjectQuery,
  useGetDocumentByUuidQuery,
  useGetSectionReferencesByDocumentUuidQuery,
  useDeleteDocumentMutation,
  useUpdateDocumentByNameMutation,
  useGetDocumentStatusByProjectQuery,
  // Highlights
  useGetHighlightsQuery,
  useUpdateHighlightMutation,
  useDeleteHighlightMutation,
  useCreateHighlightMutation,
  //     Bookmarks
  useGetBookmarksQuery,
  useUpdateBookmarkMutation,
  useDeleteBookmarkMutation,
  useCreateBookmarkMutation,
  //     Query Logs
  useUpdateQueryLogMutation,
  // Links
  useGetInTextLinksQuery,
  useGetInTextSourceQuery,
  useGetDocumentPageImageQuery,
  // Segments
  useGetDocumentSegmentsQuery,
  useCreateTypesenseExportMutation,
  useGetProjectPermissionsByIdQuery,
  // Checklist
  useGetChecklistQuestionsQuery,
  useCreateChecklistQuestionMutation,
  useUpdateChecklistQuestionMutation,
  useCreateChecklistQuestionRunManyMutation,
  useGetChecklistQuestionsByTemplateViewQuery,
  useDeleteChecklistQuestionMutation,
  useCreateChecklistQuestionRunManyByViewMutation,
  // Risks
  useGetRisksByProjectQuery,
  useGetRisksByDocumentsQuery,
  // Changes
  useGetDocumentChangesByDocumentQuery,
  useCreateChatFeedbackMutation,
  useGetDocumentChangesByProjectQuery,
  useUpdateDocumentChangesMutation,
  useGetDocumentChangesQuery,
  useDocumentChangeRunManyMutation,
  useCreateDocumentChangesExportMutation,
  useUpdateDocumentChangeCommentMutation,
  useCreateDocumentChangeCommentMutation,
  useDeleteDocumentChangeCommentMutation,
  useGetNotificationsQuery,
  useMarkNotificationsAsReadMutation,
  useGetOrganizationUsersQuery,
  useInviteOrgToProjectMutation,
  useCreateDocumentMutation,
  useGetDefinitionsByQueryQuery,
  useCreateRiskExportMutation,
  useGetDocumentBlacklinesQuery,
  useExportDocumentDiffMutation,
  useCreateTaskFromSegmentsMutation,
  useUpdateTaskStatusMutation,
  useUpdateTaskDescriptionMutation,
  useDeleteTaskMutation,
  useGetTaskStatsQuery,
  useUnarchiveProjectMutation,
  useGetAddendaSegmentsQuery,
  useUpdateDocumentSortMutation,
  useDeleteFolderMutation,
  useCreateFolderMutation,
  useRenameFolderMutation,
  useMoveItemsIntoFolderMutation,
  useGetDocumentsListByProjectQuery,
  useDeleteDocumentChangeDestinationMutation,
} = apiSlice;
